<template>
  <div class="coupon" @click.stop>
    <h3>
      <div class="title">{{ title }}</div>
      <div class="close" @click="close()">
        <SvgIcon name="close" color="#8d8d8d" />
      </div>
    </h3>

    <div class="content">
      <div class="coupon_list" v-for="(item, index) in info" :key="index">
        <div class="coupon_list_info">
          <div class="coupon_img">
            <SvgIcon name="yhq" color="#fff" />
          </div>
          <div class="coupon_info">
            <div class="coupon_decrement">满10000送100</div>
            <div class="coupon_num">数量：1张</div>
          </div>
          <div class="coupon_date">有效期1天</div>
        </div>
        <div class="coupon_list_top"></div>
        <div class="coupon_list_bottom"></div>
      </div>
    </div>
    <div class="submit_btn" @click="ToCoupon()">前往查看</div>
    <!-- <div class="no_remind">今日不再提醒</div> -->
    <label class="today-reminder">
      <input type="checkbox" v-model="checkbox" />
      <span class="checkmark"></span>
      <span class="label-text">今日不再提醒</span>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "优惠券",
      info: [
        { title: "满10000送100", num: "1", date: "1" },
        { title: "满10000送100", num: "1", date: "1" },
        { title: "满10000送100", num: "1", date: "1" },
      ],
      checkbox: false,
      content: "111",
    };
  },
  methods: {
    close() {
      this.$router.push("/main");
    },
    ToCoupon() {
      let expires = Date.now() + 1000 * 60 * 60 * 24; //24小时
      localStorage.setItem(
        "couponCheck",
        JSON.stringify({ data: this.checkbox, expires, expires })
      );
      this.$router.push("/main/coupon");
    },
  },
};
</script>

<style scoped lang="scss">
.coupon {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 8.5rem;
  width: 6rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: 0.3s;
  background-color: #fff;
  padding: 0 0.2rem;
}
h3 {
  display: flex;
  justify-content: space-between;
  width: 55%;
  margin-left: 42%;
  .title {
    align-self: center;
  }
  .close {
    width: 0.47rem;
    height: 0.47rem;
    margin-left: auto;
    svg {
      width: 0.47rem;
      height: 0.47rem;
    }
  }
}

.content {
  height: 6rem;
  width: 100%;
  overflow: auto;
}
.coupon_list {
  position: relative;
  height: 2rem;
  border-radius: 0.2rem;
  background-color: $color-primary;
  color: #fff;
  margin: 0.2rem 0;
  .coupon_list_info {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .coupon_img {
      padding: 0.15rem;
      width: 0.47rem;
      height: 0.47rem;
      min-height: 0.47rem;
      border-radius: 50%;
      overflow: hidden;
      svg {
        width: 0.47rem;
        height: 0.47rem;
      }
    }
    .coupon_info {
      margin-right: 1rem;
      height: 1.2rem;
      line-height: 0.6rem;
    }
  }
  .coupon_list_top {
    position: absolute;
    top: -0.25rem;
    right: 2rem;
    width: 0.6rem;
    height: 0.6rem;
    background-color: #fff;
    border-radius: 1rem;
  }
  .coupon_list_bottom {
    position: absolute;
    bottom: -0.25rem;
    right: 2rem;
    width: 0.6rem;
    height: 0.6rem;
    background-color: #fff;
    border-radius: 1rem;
  }
}
.submit_btn {
  margin: 0.2rem 0;
  width: 90%;
  line-height: 0.7rem;
  text-align: center;
  background-color: $color-primary;
  color: #fff;
  border-radius: 1rem;
}
.no_remind {
  margin-bottom: 0.2rem;
}
.today-reminder {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
}

.today-reminder input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.3rem;
  width: 0.3rem;
  border-radius: 50%;
  border: 2px solid #bbb;
}

.today-reminder:hover input ~ .checkmark {
  background-color: #ccc;
}

.today-reminder input:checked ~ .checkmark {
  background-color: $color-primary;
  border-color: $color-primary;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.today-reminder input:checked ~ .checkmark:after {
  display: block;
}

.today-reminder .label-text {
  margin-left: 0.1rem;
  line-height: 0.15rem;
}
</style>